import React from 'react'

import Layout from '../components/Layout'
import { useSiteMetadata } from '../hooks'
import Hero from '../components/Hero'
import { Link } from 'gatsby'

const Tickets = () => {
  const { title, description } = useSiteMetadata()

  return (
    <Layout
      title={`Tickets | ${title}`}
      description={description}
    >
      <Hero className={'hero is-transparent is-medium'} isIndex={false} title={'Tickets'}/>

      <div className={'columns has-padding-top-30 has-padding-bottom-30 has-padding-20-mobile'}>
        <div className={'column is-offset-one-quarter is-half'}>
          <div className={'columns has-text-centered'}>
            <div className={'column'}>
              <div className={'columns has-padding-bottom-50'}>
                <div className={'column is-12-mobile is-6-desktop is-6-tablet'}>
                  <div className={'card'} style={{ height: '100%' }}>
                    <header className="card-header has-background-link-dark">
                      <p className="card-header-title">
                        <h4 className={'title is-2-tablet is-4-mobile has-text-link-light'}>Conference Tickets</h4>
                      </p>
                    </header>
                    <div className="card-content">
                      <p>An amazing value ticket for everyone!</p>
                      <br/>
                      <hr/>
                      <h3 className={'is-size-4 has-text-weight-semibold'}>$299</h3>
                      <hr/>
                      <div className={'has-text-left'}>
                        <p className={'has-padding-bottom-20'}>A full day of amazing food, in depth conference sessions
                          and
                          fun activities</p>
                        <p className={'has-padding-bottom-20'}>Available until the event is sold out</p>
                        <p className={'has-padding-bottom-20'}>Group discount available</p>
                      </div>
                    </div>
                    <div className={'card-footer has-margin-left-10 has-margin-right-10 has-padding-top-10'}
                         style={{
                           display: 'inline-block',
                           alignSelf: 'flex-end',
                         }}>
                      <p className={'card-footer-item'}>
                        <a href={'https://www.trybooking.com/book/event?eid=629452'}
                           className="button is-success is-small is-fullwidth is-uppercase">
                          <span>Get Your Tickets</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default Tickets

